import { Icons } from '@/assets/icons'
import { CaseStudyAssetsProps } from '../types'

const BASE_URL = `assets/case-studies/the-frida-project`

export const TheFridaProject: CaseStudyAssetsProps = {
  colors: {
    'blue-2': '#81CDE1',
  },
  landing: {
    background: `${BASE_URL}/landing/background.png`,
  },
  features: {
    'background-1': `${BASE_URL}/features/background-1.png`,
    'background-2': `${BASE_URL}/features/background-2.png`,
    cards: [
      { device: `${BASE_URL}/features/device-1.png`, icon: Icons.Pencil },
      { device: `${BASE_URL}/features/device-2.png`, icon: Icons.Message },
      { device: `${BASE_URL}/features/device-3.png`, icon: Icons.Users },
      { device: `${BASE_URL}/features/device-4.png`, icon: Icons.Files },
    ],
  },
  'mockup-display': {
    image: `${BASE_URL}/mockup-display/image.png`,
  },
  solution: {
    image: `${BASE_URL}/solution/cover.png`,
  },
}
