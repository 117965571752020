import { Theme } from '@/app'
import { Icons } from '@/assets/icons'
import { CaseStudyAssetsProps } from '../types'

const BASE_URL = `assets/case-studies/aws-cloudroom`

export const AWSCloudroom: CaseStudyAssetsProps = {
  colors: {
    'aws-cloudroom-sky': '#4773C8',
    textColor: Theme.colors.white,
  },
  landing: {
    background: `${BASE_URL}/landing/background.png`,
    'device-1': `${BASE_URL}/landing/device-1.png`,
    'device-2': `${BASE_URL}/landing/device-2.png`,
  },
  solution: {
    image: `${BASE_URL}/solution/cover.png`,
  },
  reviews: {
    background: `${BASE_URL}/reviews/background.png`,
  },
  'tech-stack': {
    image: `${BASE_URL}/tech-stack/image.png`,
  },
  'mockup-display': {
    image: `${BASE_URL}/mockup-display/image.png`,
  },
  features: {
    background: `${BASE_URL}/features/background.png`,
    cards: [
      { device: `${BASE_URL}/features/device-1.png`, icon: Icons.ClipboardEdit },
      { device: `${BASE_URL}/features/device-2.png`, icon: Icons.CodePen },
      { device: `${BASE_URL}/features/device-3.png`, icon: Icons.Layers },
      { device: `${BASE_URL}/features/device-4.png`, icon: Icons.Stack },
    ],
  },
}
