import { CaseStudyAssetsProps } from '../types'

const BASE_URL = `assets/case-studies/bearable`

export const Bearable: CaseStudyAssetsProps = {
  features: {
    'first-card': {
      device: `${BASE_URL}/features/device-1.png`,
      icon: '',
    },
    'second-card': {
      device: `${BASE_URL}/features/device-2.png`,
      icon: '',
    },
    'full-width-card-devices': `${BASE_URL}/features/full-width-card-device.png`,
    'splitted-card-device': `${BASE_URL}/features/splitted-card-device.png`,
  },
  solution: {
    image: `${BASE_URL}/solution/solution-cover.png`,
  },
}
