import { Theme } from '@/app'
import DemoDevice, { getDemoImageScale } from '.'
import { React, Image, View } from '@/lib'
import Slider from 'react-slick'
import ReactDevicePreview from 'react-device-preview'
import { styles } from './styles'

const sliderSettings = {
  dots: false,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 3000,
}

export const DemoSlider = (props) => {

  const { images, position, style, adjust, imageStyle, device, alt } = props

  const { width } = Theme.hooks.size()

  const actualScale = getDemoImageScale(width, adjust)

  const wrapperStyle = {
    width: DemoDevice.width * actualScale,
    height: DemoDevice.height * actualScale,
  }

  return (
    <View style={[styles.deviceWrapper, wrapperStyle, style]}>
      <ReactDevicePreview
        device={device || 'iphonex'}
        scale={actualScale}
        position={position || 'portrait'}
      >
        <Slider {...sliderSettings}>
          {images?.length ? images.map?.((image, index) => (
            <Image
              alt={`${alt} demo ${index}`}
              key={index}
              loading={'eager'}
              src={image}
              style={imageStyle}
            />
          )) : null}
        </Slider>
      </ReactDevicePreview>
    </View>
  )
}
