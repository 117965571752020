import { Theme } from '@/app'
import { CaseStudyAssetsProps } from '../types'

const BASE_URL = 'assets/case-studies/eworkflow'

export const Eworkflow: CaseStudyAssetsProps = {
  colors: {
    textColor: Theme.colors.white,
  },
  landing: {
    background: `${BASE_URL}/landing/background.png`,
    'device-1': `${BASE_URL}/landing/device-1.png`,
    'device-2': `${BASE_URL}/landing/device-2.png`,
  },
  features: {
    background: `${BASE_URL}/features/background.png`,
    cards: [
      { device: `${BASE_URL}/features/device-1.png`, icon: '' },
      { device: `${BASE_URL}/features/device-2.png`, icon: '' },
      { device: `${BASE_URL}/features/device-3.png`, icon: '' },
      { device: `${BASE_URL}/features/device-4.png`, icon: '' },
    ],
  },
  solution: {
    image: `${BASE_URL}/solution/image.png`,
  },
  'mockup-display': {
    image: `${BASE_URL}/mockup-display/image.png`,
  },
  'tech-stack': {
    image: `${BASE_URL}/tech-stack/image.png`,
  },
}
