import { Theme } from '@/app'
import { Icons } from '@/assets/icons'

const BASE_URL = `assets/case-studies/cherry`

export const Cherry = {
  colors: {
    textColor: Theme.colors.white,
  },
  background: `${BASE_URL}/background.png`,
  landing: {
    'device-1': `${BASE_URL}/landing/device-1.png`,
    'device-2': `${BASE_URL}/landing/device-2.png`,
  },
  reviews: {
    background: `${BASE_URL}/background.png`,
  },
  features: {
    background: `${BASE_URL}/features/background.png`,
    'first-card': {
      device: `${BASE_URL}/features/device-1.png`,
      icon: Icons.Map,
    },
    'second-card': {
      device: `${BASE_URL}/features/device-2.png`,
      icon: Icons.MapPin,
    },
    'third-card': {
      device: `${BASE_URL}/features/device-3.png`,
      icon: Icons.Code,
    },
    'fourth-card': {
      device: `${BASE_URL}/features/device-4.png`,
      icon: Icons.AlertCircle,
    },
    'fifth-card': {
      device: `${BASE_URL}/features/device-5.png`,
      icon: Icons.MessageReport,
    },
  },
  solution: {
    image: `${BASE_URL}/solution/cover.png`,
  },
}
