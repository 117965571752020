import { React, Image, View } from '@/lib'
import { Theme } from '@/app'
import { Device } from '@/components'
import { apps, DemoDevice } from '@/components/Portfolio'

type WebMobileDisplayProps = {
    devices: string[]
    app: keyof typeof apps
}

export const WebMobileDisplay = (props: WebMobileDisplayProps) => {

  const data = apps[props?.app]

  const isLarge = Theme.hooks.down('large')

  return (
    <View variant='center' style={styles.wrapper}>
      <View style={styles.innerWrapper}>

        {isLarge ? (
          <DemoDevice.DemoRow data={data} />
        ) : (
          <>
            <View style={styles.tabletWrapper}>
              <Image source={props?.devices[1]} style={styles.image}/>
            </View>

            <View style={styles.deviceWrapper}>
              <Device device={props?.devices[0]} scale={0.7} />
            </View>
          </>
        )}

      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    width: '100%',
    height: '100%',
  },
  innerWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.large / 2,
    objectFit: 'cover',
  },
  deviceWrapper: {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-60%, -50%)',
    zIndex: 999,
    width: DemoDevice.width * 0.6,
    height: DemoDevice.height * 0.6,
  },
  tabletWrapper: {
    position: 'absolute',
    top: '50%',
    left: '20%',
    transform: 'translate(-20%, -50%)',
    minWidth: 400,
    height: 430,
    backgroundColor: Theme.colors.white,
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    marginTop: Theme.spacing(6),
    [Theme.media.down('xxlarge')]: {
      left: '15%',
      transform: 'translate(-15%, -50%)',
    },
  },
}
