import { AWSCloudroom } from './aws-cloudroom'
import { AWSGirlsTechSeries } from './aws-girls-tech-series'
import { Bearable } from './bearable'
import { Becon } from './becon'
import { Cherry } from './cherry'
import { GoQuest } from './go-quest-adventures'
import { Kite } from './kite'
import { Skamper } from './skamper'
import { TheFridaProject } from './the-frida-project'
import { Eworkflow } from './eworkflow'

const BASE_URL = `assets/case-studies`

const Components = {
  others: {
    becon: `${BASE_URL}/components/others/becon.png`,
    frida: `${BASE_URL}/components/others/frida.png`,
    bearable: `${BASE_URL}/components/others/bearable.png`,
    cloudroom: `${BASE_URL}/components/others/cloudroom.png`,
  },
}

export const CaseStudies = {
  AWSCloudroom,
  AWSGirlsTechSeries,
  Bearable,
  Becon,
  Cherry,
  GoQuest,
  Kite,
  Skamper,
  Components,
  TheFridaProject,
  Eworkflow,
}
