import { ReactComponent as Mobile } from './mobile.svg'
import { ReactComponent as Web } from './web.svg'
import { ReactComponent as NextGen } from './next-gen.svg'
import { ReactComponent as AI } from './ai.svg'

import { ReactComponent as ReactNative } from './react-native.svg'
import { ReactComponent as JavaScript } from './javascript.svg'
import { ReactComponent as ObjectiveC } from './objective-c.svg'
import { ReactComponent as Java } from './java.svg'

import { ReactComponent as Python } from './python.svg'
import { ReactComponent as Django } from './django.svg'
import { ReactComponent as Database } from './database.svg'

import { ReactComponent as Cloud } from './cloud.svg'
import { ReactComponent as Firebase } from './firebase.svg'
import { ReactComponent as Sentry } from './javascript.svg'

import { ReactComponent as Apple } from './apple.svg'
import { ReactComponent as Desktop } from './desktop.svg'
import { ReactComponent as Android } from './android.svg'

import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { ReactComponent as Sliders } from './sliders.svg'
import { ReactComponent as ChevronRight } from './chevron-right.svg'
import { ReactComponent as ChevronLeft } from './chevron-left.svg'

import { ReactComponent as AlertCircle } from './alert-circle.svg'
import { ReactComponent as Code } from './code-2.svg'
import { ReactComponent as Map } from './map.svg'
import { ReactComponent as MapPin } from './map-pin.svg'
import { ReactComponent as MessageReport } from './message-report.svg'

import { ReactComponent as ClipboardEdit } from './clipboard-edit.svg'
import { ReactComponent as CodePen } from './codepen.svg'
import { ReactComponent as Layers } from './layers-intersect.svg'
import { ReactComponent as Stack } from './stack-3.svg'
import { ReactComponent as Target } from './target.svg'
import { ReactComponent as Globe } from './globe-2.svg'
import { ReactComponent as Atom } from './atom.svg'
import { ReactComponent as Circle } from './circle.svg'
import { ReactComponent as Coffe } from './coffe.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as Compass } from './compass.svg'
import { ReactComponent as Files } from './files.svg'
import { ReactComponent as HeartHandshake } from './heart-handshake.svg'
import { ReactComponent as Lightbulb } from './lightbulb.svg'
import { ReactComponent as Message } from './message-2.svg'
import { ReactComponent as Pencil } from './pencil.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Users } from './users.svg'

import { ReactComponent as Hiking } from './hiking.svg'
import { ReactComponent as MapRoute } from './map-route.svg'

import { ReactComponent as GooglePlay } from './google-play.svg'

const Services = {
  Web,
  Mobile,
  AI,
  NextGen,
}

const TechStack = {
  'Front-end': {
    ReactNative,
    JavaScript,
    ObjectiveC,
    Java,
  },
  'Back-end': {
    Python,
    Django,
    Database,
  },
  Services: {
    Cloud,
    Firebase,
    Sentry,
  },
}

export const Icons = {
  Services,
  TechStack,
  GooglePlay,
  Apple,
  Desktop,
  Android,
  ArrowRight,
  Sliders,
  ChevronRight,
  ChevronLeft,
  AlertCircle,
  Map,
  MapPin,
  Code,
  MessageReport,
  ClipboardEdit,
  CodePen,
  Layers,
  Stack,
  Target,
  Globe,
  Atom,
  Circle,
  Coffe,
  Star,
  Compass,
  Files,
  HeartHandshake,
  Lightbulb,
  Message,
  Pencil,
  User,
  Users,
  Hiking,
  MapRoute,
}
