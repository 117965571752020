import { Theme } from '@/app'
import { Icons } from '@/assets/icons'
import { CaseStudyAssetsProps } from '../types'

const BASE_URL = `assets/case-studies/becon`

export const Becon: CaseStudyAssetsProps = {
  colors: {
    textColor: Theme.colors.white,
  },
  landing: {
    background: `${BASE_URL}/landing/background.png`,
    'device-1': `${BASE_URL}/landing/device-1.png`,
    'device-2': `${BASE_URL}/landing/device-2.png`,
  },
  reviews: {
    background: `${BASE_URL}/background.png`,
  },
  features: {
    background: `${BASE_URL}/features/background.png`,
    cards: [
      { device: `${BASE_URL}/features/device-1.png`, icon: Icons.Map },
      { device: `${BASE_URL}/features/device-2.png`, icon: Icons.MapPin },
      { device: `${BASE_URL}/features/device-3.png`, icon: Icons.Code },
      { device: `${BASE_URL}/features/device-4.png`, icon: Icons.AlertCircle },
      { device: `${BASE_URL}/features/device-5.png`, icon: Icons.MessageReport },
    ],
  },
  'mockup-display': {
    image: `${BASE_URL}/mockup-display/image.png`,
  },
  solution: {
    image: `${BASE_URL}/solution/cover.png`,
  },
}
