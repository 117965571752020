import { Theme } from '../Theme'

const wrapper = {
  default: {
    maxWidth: Theme.values.sizes.large,
    maxHeight: Theme.values.sizes.large,
    minWidth: Theme.values.sizes.large,
    minHeight: Theme.values.sizes.large,
    backgroundColor: '#E8E8E8',
    borderRadius: Theme.values.borderRadius.small,
    marginBottom: Theme.spacing(4),
    ...Theme.flex,
    ...Theme.center,
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  black: {
    backgroundColor: '#262635',
  },
  'the-frida-project-orange': {
    backgroundColor: '#EE7F39',
  },
  'cloudroom-sky': {
    backgroundColor: '#183177',
  },
  'girls-tech-series-pink': {
    backgroundColor: '#AF103D',
  },
  'skamper-blue': {
    backgroundColor: '#0F1696',
  },
  'go-quest-red': {
    backgroundColor: '#960112',
  },
  'kite-green': {
    backgroundColor: '#0E7D4D',
  },
  'eworkflow-gray': {
    backgroundColor: '#454545',
  },
}

const icon = {
  default: {
    color: 'white',
  },
  black: {
    color: '#FFF',
  },
}

export default {
  wrapper,
  icon,
}
