import { React, View, Tools, ViewProps, TextProps, Icon, Touchable } from '@/lib'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Theme } from '@/app'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Assets } from '@/assets'
import { Tilt } from '@jdion/tilt-react'

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: Theme.breakpoints.largeish },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: Theme.breakpoints.largeish, min: Theme.breakpoints.mid },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: Theme.breakpoints.mid, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

type ItemsSliderProps = {
  data: any[]
  renderItem: (item, props) => void
  wrapperProps?: ViewProps
  titleProps?: TextProps
  sliderMaxWidth?: number
  descriptionProps?: TextProps
}

export const ItemsSlider = (props: ItemsSliderProps) => {

  const { width: maxContentWidth } = Tools.getMaxContentWidth()
  const { width } = Theme.hooks.size()

  const [selectedPageSlide, setSelectedPageSlide] = useState(1)

  const { data } = props

  const itemsPerSlide = useMemo(() => {

    const isDesktop = width <= responsive.desktop.breakpoint.max && width >= responsive.desktop.breakpoint.min
    const isTablet = width <= responsive.tablet.breakpoint.max && width >= responsive.tablet.breakpoint.min
    const isMobile = width <= responsive.mobile.breakpoint.max && width >= responsive.mobile.breakpoint.min

    if (isDesktop) {
      return responsive.desktop.slidesToSlide
    }
    if (isTablet) {
      return responsive.tablet.slidesToSlide
    }
    if (isMobile) {
      return responsive.mobile.slidesToSlide
    }
  }, [width])

  if (!data?.length) return null

  const carouselRef = useRef(null)

  const isMobile = Theme.hooks.down('mid')

  const sliderMaxWidth = props?.sliderMaxWidth ? props?.sliderMaxWidth : maxContentWidth

  const NextArrow = () => {
    return (
      <Touchable style={[styles.arrowWrapper, styles.nextArrow]} onPress={() => carouselRef.current.next()}>
        <Icon path={Assets.Icons.ChevronRight} />
      </Touchable>
    )
  }

  const PreviousArrow = () => {
    return (
      <Touchable style={[styles.arrowWrapper, styles.previousArrow]} onPress={() => carouselRef.current.previous()}>
        <Icon path={Assets.Icons.ChevronLeft} />
      </Touchable>
    )
  }

  const Dot = ({ item, index }) => {
    const isDotSelected = selectedPageSlide === index + 1
    const bgColor = Theme.colors[isDotSelected ? 'neutral1' : 'neutral8']
    return (
      <View style={[styles.dot, { backgroundColor: bgColor, transform: `scale(${isDotSelected ? 1.5 : 1})` }]} />
    )
  }

  const dotsAmount = data.length / itemsPerSlide

  const showPreviousArrow = selectedPageSlide !== 1 && !isMobile
  const showNextArrow = (data.length / itemsPerSlide) !== selectedPageSlide && !isMobile

  const renderItem = useCallback((params) => {

    const { item, index } = params

    return (
      <Tilt key={item.title} style={styles.itemWrapper} options={{ scale: 1.02, max: 4, speed: 2000 }}>
        {props.renderItem(item, index, props)}
      </Tilt>
    )
  }, [isMobile, selectedPageSlide])

  return (
    <View style={[styles.wrapper, { maxWidth: sliderMaxWidth }]}>
      {showPreviousArrow ? <PreviousArrow /> : null}
      <Carousel
        responsive={responsive}
        arrows={false}
        ref={carouselRef}
        minimumTouchDrag={100}
        afterChange={(previousSlide, { currentSlide }) => setSelectedPageSlide(Math.floor(currentSlide / itemsPerSlide) + 1)}
      >
        {data?.map?.((item, index) => renderItem({ item, index }))}
      </Carousel>
      {showNextArrow ? <NextArrow /> : null}
    </View>
  )
}

const styles = {
  wrapper: {
    width: '100%',
    position: 'relative',
    paddingLeft: Theme.spacing(1),
  },
  cardWrapper: {
    padding: Theme.spacing(5),
    boxSizing: 'border-box',
  },
  itemWrapper: {
    marginRight: Theme.spacing(2),
    marginLeft: Theme.spacing(1),
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
    backgroundColor: 'blue',
    borderRadius: Theme.values.borderRadius.largeish,
    overflow: 'hidden',
  },
  arrowWrapper: {
    position: 'absolute',
    top: '40%',
    display: 'flex',
  },
  nextArrow: {
    right: -50,
    [Theme.media.down('largeish')]: {
      right: -30,
    },
  },
  previousArrow: {
    left: -50,
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: Theme.values.borderRadius.round,
    marginRight: Theme.spacing(1),
  },
}

export default ItemsSlider
