const BASE_URL = 'assets/logos'

export const Logos = {
  AppStore: `${BASE_URL}/app-store.png`,
  PlayStore: `${BASE_URL}/play-store.png`,
  AutoTrader: `${BASE_URL}/autotrader.png`,
  CedarsSinais: `${BASE_URL}/cedars-sinai.png`,
  ClutchAI: `${BASE_URL}/clutch_ai.png`,
  ClutchIOT: `${BASE_URL}/clutch_iot.png`,
  ClutchMachineLearning: `${BASE_URL}/clutch_machine_learning.png`,
  ClutchTopAppDev: `${BASE_URL}/clutch-top-app-dev.png`,
  ClutchTopUX: `${BASE_URL}/clutch-top-ux.png`,
  CodeleapLogoBlack: `${BASE_URL}/codeleap_logo_black.png`,
  CodeleapLogoWhite: `${BASE_URL}/codeleap_logo_white.png`,
  CodeleapLogoGray: `${BASE_URL}/codeleap_logo_gray.png`,
  CodeleapLogoGrayish: `${BASE_URL}/codeleap_logo_grayish.png`,
  Dealerships: `${BASE_URL}/dealerships.png`,
  Dexters: `${BASE_URL}/dexters.png`,
  DoverStreetMarket: `${BASE_URL}/dover-street-market.png`,
  EveningStandard: `${BASE_URL}/evening-standard.png`,
  Forbes: `${BASE_URL}/forbes.png`,
  Jet2Holidays: `${BASE_URL}/jet-2-holidays.png`,
  'aws-black-logo': `${BASE_URL}/aws-black-logo.png`,
  'aws-white-logo': `${BASE_URL}/aws-white-logo.png`,
  'aws-gray-logo': `${BASE_URL}/aws-gray-logo.png`,
  Lookers: `${BASE_URL}/lookers.png`,
  Marble: `${BASE_URL}/marble-logo.png`,
  MarieClaire: `${BASE_URL}/marie-claire.png`,
  Metro: `${BASE_URL}/metro.png`,
  PsychCentral: `${BASE_URL}/psych-central.png`,
  SweatyBetty: `${BASE_URL}/sweaty-betty.png`,
  USPainFoundation: `${BASE_URL}/us-pain-foundation.png`,
  WebMD: `${BASE_URL}/web-md.png`,
  Eworkflow: `${BASE_URL}/eworkflow-logo.png`,
  Kite: `${BASE_URL}/kite-logo.png`,
  'GQA-1': `${BASE_URL}/gqa-logo-1.png`,
  'GQA-2': `${BASE_URL}/gqa-logo-2.png`,
  Skamper: `${BASE_URL}/skamper-logo.png`,
  'Marble-2': `${BASE_URL}/marble-logo-2.png`,
  'Autoimaging': `${BASE_URL}/autoimaging-logo.png`,
  'Bearable-1': `${BASE_URL}/bearable-logo-1.png`,
  'Bearable-2': `${BASE_URL}/bearable-logo-2.png`,
  'Becon': `${BASE_URL}/becon-logo-2.png`,
  'Becon-transparent': `${BASE_URL}/becon-transparent-logo.png`,
  'Cherry-1': `${BASE_URL}/cherry-logo-1.png`,
  'Cherry-2': `${BASE_URL}/cherry-logo-2.png`,
  Frida: `${BASE_URL}/frida-logo.png`,
  GlocalX: `${BASE_URL}/glocal-x-logo.png`,
}
