import { Theme } from '@/app'
import { DemoDevice, Device, IconContainer, Reveal } from '@/components'
import tweenProps from '@/components/Reveal/tweenProps'
import { React, Tools, View, Text, Image } from '@/lib'

const getImageScale = (width, adjust = 0.95) => {
  let actualScale = adjust
  let currentAdjust = adjust
  if (width <= Theme.breakpoints.large) {
    currentAdjust = 0.7
    actualScale = adjust * currentAdjust
  } else if (width <= Theme.breakpoints.xlarge) {
    currentAdjust = 0.8
    actualScale = adjust * currentAdjust
  } else if (width <= Theme.breakpoints.xxlarge) {
    currentAdjust = 0.95
    actualScale = adjust * currentAdjust
  }
  return {
    actualScale,
    currentAdjust,
  }
}

export const SplittedCard = ({ item, index, styles: _styles }) => {

  const { height, width } = Theme.hooks.size()

  const { actualScale } = getImageScale(width)

  const limitedPerformance = Tools.useLimitedPerformance()
  const { numberWidth: maxWidth } = Tools.getMaxContentWidth()

  const cardWidth = (maxWidth - Theme.spacing(6)) / 2
  const DeviceWidth = (cardWidth - (DemoDevice.width * actualScale)) / 2.2

  const isLeftItem = index === 0
  const parallaxName = isLeftItem ? '' : 'parallaxReverse'

  return (
    <View style={[styles.cardWrapper, { marginRight: Theme.spacing(isLeftItem ? 6 : 0) }]}>
      <Reveal
        variant={'fixedBackground'}
        offset={height * -0.8}
        duration={height * 2.5}
        disabled={limitedPerformance > 0}
        fixedBackgroundOpacity={tweenProps.fixedBackgroundFullOpacity}
      >
        <div className='willChangeTransform'>
          <Reveal
            variant={parallaxName}
            offset={height * 0.12}
            duration={height}
            disabled={limitedPerformance > 0}
          >
            <div className='willChangeTransform'>
              <View style={styles.cardInnerWrapper}>
                <Image source={item?.image} style={styles.background}/>

                <View style={styles.textArea}>
                  <IconContainer icon={item?.textArea?.icon} variant={_styles?.iconContainerVariant}/>
                  <Text
                    variant={`h3 marginBottom:2`}
                    text={item?.textArea?.title}
                    style={[styles.title, { ...item?.textArea?.titleProps?.style, color: _styles?.titleColor }]}
                  />
                  <Text
                    variant={`p1`}
                    text={item?.textArea?.description}
                    style={[styles.description, { ...item?.textArea?.descriptionProps?.style, color: _styles?.descriptionColor }]}
                  />
                </View>

                <View style={styles.deviceWrapper}>
                  <View style={[styles.deviceInnerWrapper, { left: DeviceWidth }]}>
                    <Device device={item?.device} scale={actualScale}/>
                  </View>
                </View>

              </View>
            </div>
          </Reveal>
        </div>
      </Reveal>
    </View>
  )
}

const styles = {
  cardWrapper: {
    width: '100%',
    borderRadius: Theme.values.borderRadius.largeish,
  },
  cardInnerWrapper: {
    height: 'max(1000px, min(75vh, 1200px))',
    backgroundColor: '#F2F2F2',
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(8),
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    borderRadius: Theme.values.borderRadius.largeish,
  },
  textArea: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: Theme.spacing(9),
    [Theme.media.down('large')]: {
      padding: Theme.spacing(5),
    },
  },
  background: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: Theme.values.borderRadius.largeish,
  },
  deviceWrapper: {
    width: '100%',
    height: '100%',
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden',
  },
  deviceInnerWrapper: {
    position: 'absolute',
    width: '100%',
    bottom: -400,
  },
}

