import { Theme } from '@/app'
import DemoDevice, { getDemoImageScale } from '.'
import { styles } from './styles'
import { React, View } from '@/lib'
import { Demo } from './Demo'
import { DemoSlider } from './Slider'
import Reveal from '@/components/Reveal'

export const DemoRow = (props) => {

  const { position, data } = props

  const { height, width } = Theme.hooks.size()
  const fits3 = Theme.hooks.up('large')
  const isLeft = position == 'left'

  const renderFirst = fits3 ? true : !isLeft
  const renderLast = fits3 ? true : isLeft
  const demoHeight = getDemoImageScale(width) * DemoDevice.height
  const duration = height + demoHeight

  return (
    <View style={styles.demoRowWrapper}>
      <View style={[styles.demoRow, isLeft && styles.demoRowLeft]} up={'mid'}>
        {renderFirst && (
          <Reveal
            variant={'parallaxReverse'}
            amount={isLeft ? '25vh' : '5vh'}
            offset={height * -0.5}
            duration={duration}
          >
            <div className={'willChangeTransform'}>
              {data?.images?.length ? <Demo alt={`${data?.title} demo 2`} image={data?.images[0]}/> : null}
            </div>
          </Reveal>
        )}
        <Reveal
          variant={'parallaxReverse'}
          amount={'15vh'}
          offset={height * -0.5}
          duration={duration}
        >
          <div className={'willChangeTransform'}>
            {data?.images?.length ? <Demo alt={`${data?.title} demo 2`} image={data?.images[1]}/> : null}
          </div>
        </Reveal>
        {renderLast && (
          <Reveal
            variant={'parallaxReverse'}
            amount={isLeft ? '5vh' : '25vh'}
            offset={height * -0.5}
            duration={duration}
          >
            <div className={'willChangeTransform'}>
              {data?.images?.length ? <Demo alt={`${data?.title} demo 2`} image={data?.images[2]}/> : null}
            </div>
          </Reveal>
        )}
      </View>
      <View style={[styles.demoRow, isLeft && styles.demoRowLeft]} down={'mid'}>
        <DemoSlider images={data?.images} alt={data?.title}/>
      </View>
    </View>
  )
}
